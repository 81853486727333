export enum FeatureFlag {
  PROPERTIES_FLAG = 'display-properties-screen-on-TGPortal',
  DISPLAY_NEW_DETAILS = 'display-new-details-on-TGPortal',
  VOUCH_PREMIUM_PER_DEAL = 'vouch-premium-per-deal',
  REBRAND_V2_MSA = 'rebrand-v2-msa',
  SERVER_SIDE_PAGINATION_MSA_ADMIN = 'server_side_pagination_MSA_admin',
  NEW_TG_AUTH_MSA = 'new-tg-auth-msa',
  APPLICATION_LEVEL_COVERAGE_CHANGE = 'application_level_coverage_change',
  CHANGE_POLICY_FOR_PROCESSINGPAYMENT_APPS = 'change_policy_for_processingpayment_apps',
  MANAGE_PREMIUM_REFUND_FOR_CANCELLATIONS = 'manage_premium_refund_for_cancellations',
  PREMIUM_CANCELLATION_FEE = 'premium_cancellation_fee',
  MANAGE_REFUND_NO_ORIGINAL_PAYMENT = 'manage_refund_no_original_payment',
  BOND_DEC_PAGE_MSA = 'bond_dec_page_MSA',
  SL1_MSA = 'sl1_MSA',
  SL2_MSA = 'sl2_MSA',
  FIX_DISPLAYED_AMOUNT_MSA_PORTAL = 'fix-displayed-amount-msa-portal',
  DISALLOW_REFUND_LARGER_THAN_PAYMENT = 'disallow_refund_larger_than_payment',
  SHOW_GAI_ON_ADMIN_PORTAL = 'show_GAI_on_MSA_admin_portal',
  LL_SCREENING_STATUS = 'add_ll_screening_status_to_MSA_admin_portal',
  REN_3300_SCREENING_STATUS = 'REN-3300-SCREENING-STATUS',
  CHANGE_DEFAULT_RETURNED_PREMIUM_FOR_CANCELLATION = 'change_default_returned_premium_for_cancellation',
  ADD_DEFAULT_COVERAGE_RULES = 'add_default_coverage_rules_to_MSA_Admin_Portal',
  USE_DEFAULT_RULES_FOR_UNKNOWN_SCREENING_STATUS = 'use_default_rules_for_unknown_screening_status',
  RELAOD_APPLICATION_AFTER_ERROR = 'msa_admin_portal_reload_application_after_error',
  ADDRESSUNIT_REQUIRED = 'ADDRESSUNIT_REQUIRED',
  PRICING_REFACTORING = 'PRICING_REFACTORING',
  ENABLE_NY_ES = 'enable_ny_es',
  REN_4975_SHOW_DECISION_SUMMARY_INFO = 'REN-4975_show_decision_summary_info',
  RENTER_POLICY_DOCUMENTS_UNIFICATION = 'renter_policy_documents_unification',
}
