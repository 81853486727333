// datadog has to be the first import (before apollo and others)
// now the rest of the imports
import './datadog';
import './assets/scss/index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router as ReactRouter } from 'react-router-dom';
import { SplitFactoryProvider } from '@splitsoftware/splitio-react';
import * as designSystem from '@theguarantors/ui-kit-v3/design-system';
import { GlobalStyles } from '@theguarantors/ui-kit-v3';
import { CssBaseline, StylesProvider, ThemeProvider as MUIThemeProvider } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';
import { history } from './api/apollo/client';
import { Router, theme as oldTheme, Shell } from './components';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from '@auth0/auth0-react';
import config from './config/configuration';
import { BaseRoutes } from './components/router/router.constants';
import { AuthorizedApolloProvider } from './authorizedApolloProvider';
import '@theguarantors/ui-kit-v3/fonts/fonts.css';
import useFeatureFlag from './hooks/use-feature-flag/use-feature-flag';
import { FeatureFlag } from './hooks/use-feature-flag/feature-flag';
import { Loading } from './components/loading';
import { TGAuthRouter } from './components/router/router';

const auth0Config = config().auth0;
const splitIOConfig = config().splitIO;

const Auth0Wrapper = ({ children }: { readonly children: React.ReactElement }): React.ReactElement => {
  const [, { isReady: isFlagReady, isEnabled: isNewTgAuthMsa }] = useFeatureFlag(FeatureFlag.NEW_TG_AUTH_MSA);

  if (!isFlagReady) {
    return <Loading />;
  }

  if (isNewTgAuthMsa) {
    return children;
  }

  return (
    <Auth0Provider
      useRefreshTokens
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      audience={auth0Config.audience}
      redirectUri={window.location.origin + BaseRoutes.AUTH_CALLBACK}
      access_type="offline"
      cacheLocation="localstorage"
      advancedOptions={{
        defaultScope: 'openid profile email',
      }}
    >
      {children}
    </Auth0Provider>
  );
};

const TGAuthRouterWrapper = (): React.ReactElement => {
  const [, { isReady: isFlagReady, isEnabled: isNewTgAuthMsa }] = useFeatureFlag(FeatureFlag.NEW_TG_AUTH_MSA);

  if (!isFlagReady) {
    return <Loading />;
  }

  if (isNewTgAuthMsa) {
    return <TGAuthRouter />;
  }

  return <Router />;
};

let prevPath: string | null = null;
history.listen((location) => {
  if (location.pathname !== prevPath) {
    prevPath = location.pathname;
    // @ts-expect-error
    window.analytics?.page();
  }
});

(async () => {
  ReactDOM.render(
    <React.StrictMode>
      <SplitFactoryProvider config={splitIOConfig}>
        <Auth0Wrapper>
          <MUIThemeProvider theme={oldTheme}>
            <ThemeProvider theme={designSystem.theme}>
              <StylesProvider>
                <GlobalStyles />
                <AuthorizedApolloProvider>
                  <CssBaseline />
                  <ReactRouter history={history}>
                    <Shell>
                      <TGAuthRouterWrapper />
                    </Shell>
                  </ReactRouter>
                </AuthorizedApolloProvider>
              </StylesProvider>
            </ThemeProvider>
          </MUIThemeProvider>
        </Auth0Wrapper>
      </SplitFactoryProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );

  serviceWorker.unregister();
})();
