import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import clsx from 'clsx';

import { Col, Row } from '@theguarantors/ui-kit-v3/layout/grid';
import { Box, Header2, Subheader, Text } from '@theguarantors/ui-kit-v3/design-system';
import { TableColumn, TableData } from '@theguarantors/ui-kit-v3/components/Table/Table.types';
import { Breadcrumb, Button, InfoModal, Table, Tooltip } from '@theguarantors/ui-kit-v3';
import { APPLICATION_STATUSES } from '@theguarantors/tg-modern-business';
import { Icon } from '@theguarantors/ui-kit-v3/icons';

import { DealDetailsUpdateVariables, useDealDetailsUpdate } from './hooks/use-deal-details-update';
import { ContactDetailsResult, useContactDetails } from './hooks/useContactDetails';
import { useApplicationDetails } from './hooks/use-application-details';
import { FeatureFlag, useFeatureFlag, useIsBuildingPricingRefactoringEnabled } from '../../hooks/use-feature-flag';
import { useBannerDetails } from './hooks/useBannerDetails';

import {
  getApplicationChangedValues,
  getApplicationSettingsChangedValues,
  getChangedSections,
  getErrorsCount,
  getLeaseChangedValues,
  getTruthyValuesCount,
} from './details.utils';
import { DetailsChanges, InfoState, CoverageInputDifferences } from './details.types';
import { mapAppStatusToIconProps } from '../../utils/mapAppStatusToIconProps';
import { CONTACT_TYPE, ContactTypeTextToKey } from './normalize-data';
import { StatusInfo } from '../../components/status-info/StatusInfo.component';
import { availableUpdatePolicyStatuses } from './components/CurrentPolicyInfo/current-policy-info.component';
import { CommentsSection } from './components/CommentsSection/comments-section';
import config from '../../config/configuration';
import './details.scss';
import { useApplicationCancelDeal } from './hooks/use-application-cancel-deal';
import { isNil } from '../../utils/is-nil';
import { contentColumns } from './utils/contentColumns';
import { ParseQuoteDataFromApplication } from './utils/ParseQuoteDataFromApplication';

const { monolithUrl } = config().extra;

export const Details: React.FC = () => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const [addDefaultCoverageRules] = useFeatureFlag(FeatureFlag.ADD_DEFAULT_COVERAGE_RULES);
  const [, { isEnabled: showDecisionSummaryInfoEnabled }] = useFeatureFlag(
    FeatureFlag.REN_4975_SHOW_DECISION_SUMMARY_INFO,
  );

  const { loading, application, refetch } = useApplicationDetails(
    Number(applicationId),
    addDefaultCoverageRules === 'on',
  );

  const showDecisionSummaryInfo = showDecisionSummaryInfoEnabled && Boolean(application?.workflowAudits?.length);

  const { dealDetailsUpdate, loading: dealDetailsUpdateLoading, data: dealDetailsUpdateData } = useDealDetailsUpdate();
  const { cancelDeal, loading: loadingCancelDeal } = useApplicationCancelDeal();
  const [vouchPremiumPerDealTreatment] = useFeatureFlag(FeatureFlag.VOUCH_PREMIUM_PER_DEAL);
  const [reloadApplicationAfterErrorTreatment] = useFeatureFlag(FeatureFlag.RELAOD_APPLICATION_AFTER_ERROR);
  const pricingRefactoringTreatment = useIsBuildingPricingRefactoringEnabled(application?.lease);

  const [changes, setChanges] = useState<Partial<DetailsChanges>>({});
  const [changesCount, setChangesCount] = useState<number>(0);
  const [infos, setInfos] = useState<InfoState[]>([]);
  const [isShowCancelModal, setIsShowCancelModal] = useState(false);

  const openCancelModal = useCallback(() => setIsShowCancelModal(true), []);
  const closeCancelModal = useCallback(() => setIsShowCancelModal(false), []);
  const onApproveCancelModal = useCallback(async () => {
    await cancelDeal({
      variables: {
        applicationId: Number(applicationId),
      },
    });
    await refetch();
    closeCancelModal();
  }, [applicationId, cancelDeal, closeCancelModal, refetch]);

  const contactDetailsData: Array<TableData<keyof ContactDetailsResult>> = useContactDetails({
    application,
    isReady: !loading,
  });
  const bannerData = useBannerDetails(application);
  const currentPolicy = useMemo(() => (application?.policies ? application?.policies[0] : null), [application]);

  const generateQuoteData = application
    ? new ParseQuoteDataFromApplication(application, pricingRefactoringTreatment).getQuoteData()
    : null;

  const paymentData = useMemo(() => {
    return {
      applicationId: application?.id,
      amountPaid: application?.payment?.amountPaid,
      amountRefunded: application?.payment?.amountRefunded,
      outstandingAmount: application?.payment?.outstandingAmount,
      premiumAmount: generateQuoteData?.premiumAmount,
      premiumAmountWithoutUpfrontDiscount: generateQuoteData?.premiumAmountWithoutUpfrontDiscount,
      history: application?.payment?.history ?? [],
      isVouchedPremium: application?.settings?.isVouchedPremium ?? false,
      isMonthlyPayment: application?.settings?.isMonthlyPayment ?? false,
    };
  }, [application, generateQuoteData]);

  const showPaymentCard = useMemo(
    () =>
      vouchPremiumPerDealTreatment === 'on' ||
      Boolean(
        paymentData.amountPaid ??
          paymentData.amountRefunded ??
          paymentData.outstandingAmount ??
          paymentData.premiumAmount ??
          paymentData.history.length,
      ),
    [vouchPremiumPerDealTreatment, paymentData],
  );

  const coverageInputDifferences = useMemo<CoverageInputDifferences | null>(() => {
    if (!currentPolicy) {
      return null;
    }

    return {
      leaseStartDate: currentPolicy?.lease.leaseStartDate !== application?.lease.leaseStartDate,
      leaseEndDate: currentPolicy?.lease.leaseEndDate !== application?.lease.leaseEndDate,
      addressUnit: currentPolicy?.lease.addressUnit !== application?.lease.addressUnit,
      monthlyRent: currentPolicy?.premium.context.monthlyRent !== application?.lease.monthlyRent,
      rentCoverage: currentPolicy?.lease.rentCoverage !== application?.lease.rentCoverage,
      depositsCoverage: currentPolicy?.lease.depositsCoverage !== application?.lease.depositsCoverage,
      freeRent: currentPolicy?.lease.freeRent !== application?.lease.freeRent,
      prepaidRent: currentPolicy?.lease.prepaidRent !== application?.lease.prepaidRent,
      lgCoverageOverride: isNil(application?.lgCoverageOverride)
        ? currentPolicy?.premium.context.coverageMonths !== generateQuoteData?.coverageMonths
        : currentPolicy?.premium.context.coverageMonths !== application?.lgCoverageOverride,
      sdrCoverageOverride: isNil(application?.sdrCoverageOverride)
        ? currentPolicy?.premium.context.amountSDR !== generateQuoteData?.amountSDR
        : currentPolicy?.premium.context.amountSDR !== application?.sdrCoverageOverride,
      premium: application?.settings?.isMonthlyPayment
        ? currentPolicy.premium.context?.premiumAmountWithoutUpfrontDiscount !==
          generateQuoteData?.premiumAmountWithoutUpfrontDiscount
        : currentPolicy.premium.amount !== generateQuoteData?.premiumAmount,
    };
  }, [application, currentPolicy, generateQuoteData]);

  const isLeaseHasDifferences = coverageInputDifferences && getTruthyValuesCount(coverageInputDifferences) > 0;

  const getRowProps = useCallback((props) => {
    const conactTypeKey = ContactTypeTextToKey[props.original.contactType?.value];

    return {
      className: clsx({
        details__row: true,
        [`details__row--${conactTypeKey as CONTACT_TYPE}`]: conactTypeKey,
      }),
    };
  }, []);

  const getColumnProps = useCallback((props) => {
    const { id } = props;

    return {
      className: clsx({
        'details__column--email': id === 'email',
        'fs-mask': ['email', 'phoneNumber', 'fullName'].includes(id),
      }),
    };
  }, []);

  const appStatusProps = useMemo(() => {
    if (!application?.status) return null;
    return mapAppStatusToIconProps(application.status);
  }, [application]);

  const resetChanges = useCallback(() => {
    setChanges({});
  }, []);

  const externalDealUrl = useMemo(
    () =>
      application?.externalApplicationId
        ? `${monolithUrl}/admin/applications/${application.externalApplicationId}/edit`
        : '',
    [application],
  );

  const saveButtonText = useMemo(() => `Save ( ${changesCount} ) Change${changesCount > 1 ? 's' : ''}`, [changesCount]);

  const saveChanges = useCallback(async () => {
    const dealId = application?.deal.id;
    if (!dealId) return;

    const detailsToUpdate: DealDetailsUpdateVariables['detailsToUpdate'] = {
      lease: getLeaseChangedValues(changes?.lease),
      applicationSettings: getApplicationSettingsChangedValues(changes?.applicationSettings),
      application: getApplicationChangedValues(changes?.application),
    };

    let info: InfoState;

    try {
      await dealDetailsUpdate({
        variables: {
          dealId,
          detailsToUpdate,
        },
      });
      await refetch(); // refetch applicationDetails
      info = {
        id: Date.now(),
        message: 'Changes saved.',
        type: 'success',
      };
    } catch (err) {
      if (reloadApplicationAfterErrorTreatment === 'on') {
        await refetch(); // refetch applicationDetails
      }

      info = {
        id: Date.now(),
        message: (err as ApolloError).message ?? 'Error saving. Try again later.',
        type: 'error',
      };
    }

    setInfos((prevState) => [...prevState, info]);
    resetChanges();
  }, [application, changes, setInfos, refetch, resetChanges, dealDetailsUpdate, reloadApplicationAfterErrorTreatment]);

  const handleChanges = useCallback((name: keyof DetailsChanges, changes: Record<string, unknown> | undefined) => {
    setChanges((prevState) => {
      return {
        ...prevState,
        [name]: changes && Object.keys(changes).length ? changes : undefined,
      };
    });
  }, []);

  // update changes count on each change done
  useEffect(() => {
    const changedSections = getChangedSections(changes);
    const changesCount = changedSections.reduce((res, cur) => {
      res += getTruthyValuesCount(cur);
      return res;
    }, 0);
    setChangesCount(changesCount);
  }, [changes]);

  const errorsCount: number = useMemo(() => {
    const changedSections = getChangedSections(changes);
    return changedSections.reduce((length, section) => {
      length += getErrorsCount(section);
      return length;
    }, 0);
  }, [changes]);

  const shouldDisableSaveButton: boolean = useMemo(() => {
    return dealDetailsUpdateLoading || errorsCount > 0;
  }, [dealDetailsUpdateLoading, errorsCount]);

  const isUpdatePolicyUnavailable = useMemo(
    () => !availableUpdatePolicyStatuses.includes(currentPolicy?.status ?? ''),
    [currentPolicy],
  );
  const isCancelButtonActive = useMemo(
    () => isUpdatePolicyUnavailable && application?.status !== APPLICATION_STATUSES.CLOSED_LOST,
    [application?.status, isUpdatePolicyUnavailable],
  );

  const contactDetailsColumns: Array<TableColumn<keyof ContactDetailsResult>> = useMemo(
    () => [
      { Header: 'Contact Type', accessor: 'contactType' },
      { Header: 'Name', accessor: 'fullName' },
      { Header: 'Email', accessor: 'email' },
      { Header: 'Phone Number', accessor: 'phoneNumber' },
    ],
    [],
  );

  const cancelButton = useMemo(() => {
    if (isCancelButtonActive) {
      return (
        <Button
          bType="primary"
          bVariant="outlined"
          size="s"
          width="100%"
          mt="sm"
          disabled={!isCancelButtonActive}
          onClick={openCancelModal}
        >
          Cancel Deal
        </Button>
      );
    }

    return (
      <Tooltip
        multiline
        tooltipTitle="A policy exists for this deal. <br />Use the Cancel Policy button below"
        tooltipId="dSmallButton"
        place="bottom"
        effect="solid"
        mode="dark"
      >
        <Button
          bType="primary"
          bVariant="outlined"
          size="s"
          width="100%"
          mt="sm"
          disabled={!isCancelButtonActive}
          onClick={openCancelModal}
        >
          Cancel Deal
        </Button>
      </Tooltip>
    );
  }, [isCancelButtonActive, openCancelModal]);

  const applicationDisplayName = useMemo(() => {
    const displayName = application?.lease?.property.displayName ?? '';
    const separator = displayName ? ' - ' : '';
    const unitNumber = application?.lease?.addressUnit ?? application?.renterProvidedData?.apartmentNumber ?? '';

    return `${displayName}${separator}${unitNumber}`;
  }, [application?.lease, application?.renterProvidedData?.apartmentNumber]);

  const { leftColumn, rightColumn } = contentColumns({
    application,
    changes,
    handleChanges,
    coverageInputDifferences,
    refetch,
    currentPolicy,
    paymentData,
    showPaymentCard,
    addDefaultCoverageRules,
    generateQuoteData,
    showDecisionSummaryInfo,
  });

  return application ? (
    <>
      <InfoModal
        isOpen={isShowCancelModal}
        headingVariant="primary"
        iconKey="assignment"
        secondaryIconKey="warning"
        secondaryIconColor="error.main"
        title={
          <Subheader color="text.header" textAlign="center">
            Are you sure you want to
            <br /> cancel this deal?
          </Subheader>
        }
        data-testid="cancel_deal_modal"
        description="This action cannot be undone."
        actionButtonType="error"
        actionButtonIsLoading={loadingCancelDeal}
        actionButtonLabel={
          <>
            {loadingCancelDeal && <Icon name="loading" color="other.white" />}
            Cancel Deal
          </>
        }
        cancelButtonType="neutral"
        cancelButtonVariant="outlined"
        cancelLabel="Nevermind, Go Back"
        width="40rem"
        height="30rem"
        buttonSize="m"
        onCancel={closeCancelModal}
        onAction={onApproveCancelModal}
        onClose={closeCancelModal}
      />
      <Box mb="xl">
        <Row>
          <Col span={1 / 2}>
            <>
              <Box mb="sm">
                <Breadcrumb crumbs={[{ title: 'Deals', path: '/deals' }, { title: applicationDisplayName }]} />
                <Header2 color="neutral.main">{applicationDisplayName}</Header2>
                {application?.lease?.property && (
                  <>
                    <br />
                    <Text color="neutral.main">
                      {application?.lease?.property?.mainAddress}, {application?.lease?.property?.city},{' '}
                      {application?.lease?.property?.state} {application?.lease?.property?.zipCode}
                    </Text>
                  </>
                )}
              </Box>
              {appStatusProps && (
                <div className="status-banner">
                  <Icon {...appStatusProps} className="status-icon" mr="xs" />
                  {application?.status}
                </div>
              )}
            </>
          </Col>
          <Col span={1 / 2}>
            <Box className="info-banner__wrapper">
              <Box className="info-banner__content" pt="xs" pb="xs" pl="sm" pr="sm">
                <ul data-testid="auto_deal_box" className="info-banner__list">
                  {externalDealUrl && (
                    <li key="External Deal ID" className="info-banner__row">
                      <a className="info-banner__link" href={externalDealUrl} target="_blank" rel="noreferrer">
                        <Row>
                          <Col span={1 / 2}>
                            <span>Manual Deal ID</span>
                          </Col>
                          <Col span={1 / 2}>
                            <div className="info-banner__link--icon">
                              <span>{application.externalApplicationId}</span>
                              <Icon width={22} height={16} name="arrowForward" color="primary.main" />
                            </div>
                          </Col>
                        </Row>
                      </a>
                    </li>
                  )}
                  {bannerData.map((row) => {
                    return (
                      <li key={row.key} className="info-banner__row">
                        <Row>
                          <Col span={1 / 2}>
                            <span>{row.key}</span>
                          </Col>
                          <Col span={1 / 2}>
                            <span>{row.value}</span>
                          </Col>
                        </Row>
                      </li>
                    );
                  })}
                </ul>
                {cancelButton}
              </Box>
            </Box>
          </Col>
        </Row>
      </Box>
      {contactDetailsData?.length && (
        <Box data-testid="auto_application_type" mb="xl">
          <Table<keyof ContactDetailsResult>
            columns={contactDetailsColumns}
            data={contactDetailsData}
            getRowProps={getRowProps}
            getColumnProps={getColumnProps}
          />
        </Box>
      )}
      {infos.map((info) => (
        <StatusInfo
          key={info.id}
          type={info.type}
          mb="xl"
          className="status-info"
          onClick={() => setInfos((prevState) => prevState.filter((i) => i.id !== info.id))}
        >
          {info.message}
        </StatusInfo>
      ))}
      {isLeaseHasDifferences && (
        <StatusInfo type="warning" mb="xl" className="status-info" delay={false}>
          Lease details changed, so current policy does not have most recent information
        </StatusInfo>
      )}
      <Box mb="xxxl">
        <Row hgutter="lg" vgutter="lg">
          <Col span={1 / 2}>
            <Box>
              {Object.entries(leftColumn).map(([key, item]) => (
                <Box key={key} mb="lg">
                  {item}
                </Box>
              ))}
            </Box>
          </Col>
          <Col span={1 / 2}>
            <Box>
              {Object.entries(rightColumn).map(([key, item]) =>
                item ? (
                  <Box key={key} mb="lg">
                    {item}
                  </Box>
                ) : null,
              )}
              <Box>
                <CommentsSection
                  setChanges={handleChanges}
                  commentData={
                    dealDetailsUpdateData?.applicationSettings?.comment ?? application?.settings?.comment ?? ''
                  }
                  changes={changes.applicationSettings}
                />
              </Box>
            </Box>
          </Col>
        </Row>
      </Box>
      {changesCount ? (
        <Box display="flex" justifyContent="flex-end" padding="sm" position="sticky" bottom="0" right="0">
          <Button bType="primary" bVariant="outlined" mr="sm" onClick={resetChanges}>
            Cancel
          </Button>
          <Button bType="primary" disabled={shouldDisableSaveButton} onClick={saveChanges}>
            {dealDetailsUpdateLoading ? <Icon name="loading" /> : saveButtonText}
          </Button>
        </Box>
      ) : null}
    </>
  ) : (
    <Box display="flex" width="100%" height="100%" justifyContent="center">
      <Icon size="lg" name="loading" color="primary.main" />
    </Box>
  );
};
