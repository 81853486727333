import { DISCRETIONARY_TYPES } from '@theguarantors/tg-modern-business';
import {
  ApplicationEntity,
  ApplicationQuote,
  DealEntity,
  GeneratedQuote,
  LeaseEntity,
  Quote,
  QuotePaymentDetails,
} from '../../../api/api-types';
import { QuoteData } from '../details.types';

interface ParseNewQuoteData extends Omit<QuoteData, 'rentCoverage' | 'depositsCoverage' | 'carrier' | 'riskCategory'> {}
interface ParseOldQuoteData extends Omit<QuoteData, 'rentCoverage' | 'depositsCoverage'> {}

// Utility class for parsing a new quote
export class ParseQuote {
  private readonly quote: GeneratedQuote;

  constructor(quote: GeneratedQuote) {
    this.quote = quote;
  }

  public getQuotePremium(isMonthlyPremium: boolean): QuotePaymentDetails {
    return isMonthlyPremium ? this.quote.output.monthlyPayment : this.quote.output.upfrontPayment;
  }

  public getPremiumAmount(isMonthlyPremium = false): number {
    return this.getQuotePremium(isMonthlyPremium).policyPrice;
  }

  public getAmountSaved(): number {
    return this.quote.monthlyRent - this.getPremiumAmount();
  }

  public getPenalSum(isMonthlyPremium = false): number {
    const quotePremium = this.getQuotePremium(isMonthlyPremium);
    return quotePremium.rentCoveragePenalSum + quotePremium.depositCoveragePenalSum;
  }

  public getFinalPremium(isMonthlyPremium = false): number {
    return this.getQuotePremium(isMonthlyPremium).rentCoveragePremiumFinal;
  }

  public getFinalPremiumDepositCoverage(isMonthlyPremium = false): number {
    return this.getQuotePremium(isMonthlyPremium).depositCoveragePremiumFinal;
  }

  public calculateDiscretionaryAdjustment(): number {
    const adjustment = this.quote.discretionaryAdjustment;
    if (adjustment?.configuredPct === 1) return 0;
    return adjustment?.type === DISCRETIONARY_TYPES.SURCHARGE ? adjustment?.configuredPct : -adjustment?.configuredPct;
  }

  public getRentCoveragePremium(isMonthlyPremium = false): number {
    return this.getQuotePremium(isMonthlyPremium).rentCoveragePremiumFinal;
  }
}

// Class responsible for generating and handling quotes
export class ParseQuoteDataFromApplication {
  private readonly quote: ApplicationQuote | Quote | null;
  private readonly isMonthlyPremium: boolean;
  private readonly lease: LeaseEntity;
  private readonly deal: DealEntity;
  private readonly normalizedFields: ApplicationEntity['normalizedFields'];
  private readonly quoteData: QuoteData | null;

  constructor(application: ApplicationEntity, isPricingRefactoringEnabled: boolean) {
    this.quote = application?.quote;
    this.lease = application?.lease;
    this.deal = application?.deal;
    this.isMonthlyPremium = application?.settings?.isMonthlyPayment ?? false;
    this.normalizedFields = application?.normalizedFields;
    this.quoteData = this.createQuoteData(isPricingRefactoringEnabled);
  }

  public getQuoteData(): QuoteData | null {
    return this.quoteData;
  }

  private createQuoteData(isPricingRefactoringEnabled: boolean): QuoteData | null {
    if (!this.quote) return null;

    const quoteData = isPricingRefactoringEnabled
      ? this.parseNewQuoteValue(this.quote as Quote)
      : this.parseOldQuoteValue(this.quote as ApplicationQuote);

    const carrier = isPricingRefactoringEnabled ? this.deal?.carrier ?? '' : (this.quote as ApplicationQuote).carrier;

    return {
      ...quoteData,
      rentCoverage: this.lease.rentCoverage,
      depositsCoverage: this.lease.depositsCoverage,
      carrier,
      riskCategory: this.normalizedFields?.riskCategory ?? '',
      rateFilingData: this.quote.rateFilingData ?? '',
    };
  }

  private parseNewQuoteValue(quote: Quote): ParseNewQuoteData {
    const parsedQuote = new ParseQuote(quote);
    const isMonthlyPremium = this.isMonthlyPremium;

    return {
      premiumRate: quote.premiumRate,
      monthlyRent: quote.monthlyRent,
      penalSum: parsedQuote.getPenalSum(isMonthlyPremium),
      coverageMonths: quote.rentCoverageMonths,
      amountSDR: quote.depositCoverageAmount,
      premiumAmount: parsedQuote.getPremiumAmount(isMonthlyPremium),
      premiumAmountWithoutUpfrontDiscount: parsedQuote.getPremiumAmount(true),
      lgPremiumAmount: parsedQuote.getRentCoveragePremium(isMonthlyPremium),
      sdrPremiumAmount: parsedQuote.getFinalPremiumDepositCoverage(isMonthlyPremium),
      lgPremiumAmountWithoutUpfrontDiscount: parsedQuote.getRentCoveragePremium(true),
      sdrPremiumAmountWithoutUpfrontDiscount: parsedQuote.getFinalPremiumDepositCoverage(true),
      stampingFee: quote.monthlyPaymentTaxes?.stampingFee,
      surplusLinesTax: quote.monthlyPaymentTaxes?.excessAndSurplusLinesTax,
    };
  }

  private parseOldQuoteValue(quote: ApplicationQuote): ParseOldQuoteData {
    return {
      riskCategory: quote.riskCategory,
      penalSum: quote.penalSum,
      premiumRate: quote.premiumRate,
      coverageMonths: quote.coverageMonths,
      carrier: quote.carrier,
      amountSDR: quote.amountSDR,
      monthlyRent: quote.monthlyRent,
      lgPremiumAmount: quote.lgPremiumAmount ?? quote.premium,
      sdrPremiumAmount: quote.sdrPremiumAmount ?? quote.addRiskPremium,
      premiumAmount: quote.premiumAmount,
      premiumAmountWithoutUpfrontDiscount: quote.premiumAmountWithoutUpfrontDiscount,
      lgPremiumAmountWithoutUpfrontDiscount: quote.lgPremiumAmountWithoutUpfrontDiscount,
      sdrPremiumAmountWithoutUpfrontDiscount: quote.sdrPremiumAmountWithoutUpfrontDiscount,
      stampingFee: this.isMonthlyPremium ? quote.monthlyPaymentTaxes?.stampingFee : quote.stampingFee,
      surplusLinesTax: this.isMonthlyPremium ? quote.monthlyPaymentTaxes?.surplusLinesTax : quote.surplusLinesTax,
    };
  }
}
