import { Close } from '@material-ui/icons';
import { Box, Button } from '@theguarantors/ui-kit-v3';
import React from 'react';

export const Modal: React.FC<{
  readonly isOpen: boolean;
  readonly onClose: (...args: any[]) => void;
  readonly title: string;
  readonly sideModalProps?: any;
}> = ({ isOpen, onClose, title, children, sideModalProps }) => {
  const transition = '.3s';
  const opacity = isOpen ? '1' : '0';
  const zIndex = isOpen ? '1000' : '-1';
  const transform = isOpen ? 'translateX(0)' : 'translateX(100vw)';

  return (
    <Box className="side-modal__wrapper" style={{ opacity, zIndex, transition }}>
      <Box className="side-modal" style={{ transform, transition }} {...sideModalProps}>
        <Box className="side-modal__top-bar" />
        <Box className="side-modal__content-wrapper">
          <Box className="side-modal__close-button-wrapper">
            <Close className="side-modal__close-button" onClick={onClose} />
          </Box>
          <Box className="side-modal__header">{title}</Box>
          <Box className="side-modal__content">{children}</Box>
          <Box className="side-modal__close-button-wrapper">
            <Button onClick={onClose}>Close</Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
