import { splitIOLocalhostFeatureFlags } from '../feature-flags/constants';
import { toMockedFeaturesMap } from '../utils/mapToMockedFeaturesMap';

export interface Config {
  serverPort: string;
  extra: {
    hostname: string;
    monolithUrl: string;
  };
  services: {
    bff: {
      url: string;
    };
  };
  auth0: {
    domain: string;
    clientId: string;
    audience: string;
  };
  splitIO: {
    core: {
      authorizationKey: string;
      key: string;
    };
    features?: {
      [featureName: string]: string;
    };
  };
  tgAuthUrls: {
    loginUrl: string;
  };
}

const authorizationKey = process.env.REACT_APP_SPLIT_IO_AUTHORIZATION_KEY as string;

export default (): Config => ({
  serverPort: process.env.PORT as string,
  services: {
    bff: {
      url: process.env.REACT_APP_BFF_URL as string,
    },
  },
  extra: {
    hostname: process.env.REACT_APP_HOSTNAME as string,
    monolithUrl: process.env.REACT_APP_MONOLITH_URL as string,
  },
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE as string,
  },
  splitIO: {
    core: {
      authorizationKey: authorizationKey,
      key: process.env.REACT_APP_SPLIT_IO_KEY as string,
    },
    features: authorizationKey === 'localhost' ? toMockedFeaturesMap(splitIOLocalhostFeatureFlags) : undefined,
  },
  tgAuthUrls: {
    loginUrl: process.env.REACT_APP_LOGIN_URL ?? '',
  },
});
