import React, { useCallback, useMemo, useState } from 'react';
import {
  DecisionSummaryInfoProps,
  STEP_AUDIT_STATUS_RAW,
  WorkflowAuditWithParsedPayload,
} from './decision-summary-info.types';
import { Box, InfoContainer } from '@theguarantors/ui-kit-v3';
import { format } from 'date-fns';

import { getInfoContainerDataRow } from '../../details.utils';
import { useWorkflow } from '../../hooks/use-workflow';
import { getStepAuditStatus, recordStatusesToShow } from './decision-summary-info-helpers';
import { REPORT_STATUS } from '../../../../api/api-types';
import './decision-summary-info.scss';
import { StepAuditInfo } from './components/step-audit-info.component';
import { StepAuditStatus } from './components/step-audit-status.component';
import { DecisionFinalResultStatus } from './components/final-result-status.component';
import { Modal } from './components/modal.component';
import { ArrowForward } from '@material-ui/icons';

export const DecisionSummaryInfo: React.FC<DecisionSummaryInfoProps> = ({ application }) => {
  const { data: workflow } = useWorkflow();

  const workflowAuditsData = useMemo(() => {
    const mappedWorkflowAudits = application?.workflowAudits?.map((audit) => ({
      ...audit,
      payload: JSON.parse(audit.payload),
    }));

    return mappedWorkflowAudits?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  }, [application?.workflowAudits]);

  const workflowAuditForLatestReport = useMemo(() => {
    const latestReportId = Math.max(...(application?.reports?.map((report) => report.id) ?? []));
    const latestReport = application?.reports?.find((report) => report.id === latestReportId);

    return workflowAuditsData?.find((audit) => audit?.record?.id === latestReport?.id);
  }, [application?.reports, workflowAuditsData]);

  const [isEvaluationModalOpen, setIsEvaluationModalOpen] = useState<boolean>(false);

  const openEvaluationsModal = useCallback(() => {
    setIsEvaluationModalOpen(true);
  }, [setIsEvaluationModalOpen]);

  const closeEvaluationsModal = useCallback(() => {
    setIsEvaluationModalOpen(false);
  }, [setIsEvaluationModalOpen]);

  const getWorkflowAuditData = useCallback(
    (workflowAudit?: WorkflowAuditWithParsedPayload, showAllEvaluationsButton = false) => {
      if (!workflow || !workflowAudit) {
        return [];
      }

      const report = application?.reports?.find((report) => report.id === workflowAudit?.record?.id);

      if (!report) {
        return [];
      }

      const headerRow = getInfoContainerDataRow(<b>Step</b>, <b>Result</b>);

      const stepRows = workflow.schema
        .map((step) => {
          if (step.type !== 'rules') {
            return null;
          }

          const stepAudit = workflowAudit.stepAudit.find((audit) => audit.step === step.order);
          const relatedSchemaStep = workflow.schema.find(
            (schemaStep) => schemaStep.id === step.id && schemaStep.type === 'service',
          );
          const relatedAuditStep =
            relatedSchemaStep &&
            workflowAudit.stepAudit.find((auditStep) => auditStep.step === relatedSchemaStep.order);

          const rawResponse =
            workflowAudit.payload?.responses?.[step.id as keyof typeof workflowAudit.payload.responses];
          const modalData = rawResponse ? { serviceName: step.id, rawResponse } : undefined;

          const stepStatus =
            (stepAudit?.status === STEP_AUDIT_STATUS_RAW.NOT_EXECUTED || !stepAudit) &&
            relatedAuditStep?.status === STEP_AUDIT_STATUS_RAW.ERROR
              ? relatedAuditStep?.status
              : stepAudit?.status;

          return getInfoContainerDataRow(
            <StepAuditInfo name={step.displayName} description={step.description} />,
            <StepAuditStatus status={getStepAuditStatus(stepStatus)} modalData={modalData} />,
          );
        })
        .filter((row) => row !== null);

      const finalResultRow = getInfoContainerDataRow(
        <b>Result</b>,
        <DecisionFinalResultStatus
          status={recordStatusesToShow.includes(report.status) ? report.status : REPORT_STATUS.EXCEPTION}
        />,
      );

      const allEvaluationsRow = showAllEvaluationsButton
        ? getInfoContainerDataRow(
            <Box className="step-audit__view-all" onClick={openEvaluationsModal}>
              <ArrowForward className="decision-summary-icon" />
              <Box ml="4px" style={{ textTransform: 'capitalize' }}>
                View All Evaluations
              </Box>
            </Box>,
            null,
          )
        : null;

      return [headerRow, ...stepRows, finalResultRow, allEvaluationsRow].filter((row) => row !== null);
    },
    [workflow, application?.reports, openEvaluationsModal],
  );

  const allEvaluationsContent = useMemo(() => {
    if (!workflowAuditsData) {
      return null;
    }

    return workflowAuditsData.map((audit) => {
      const auditRows = getWorkflowAuditData(audit);
      const title = audit.evaluationType === 'final' ? 'Final Evaluation' : 'Pre-evaluation';

      return (
        <InfoContainer
          key={audit.id}
          color="neutral.main"
          className="evaluation-info-container"
          title={
            <Box className="evaluation-info-container__header">
              <Box>{title}</Box>
              <Box className="evaluation-info-container__header__update-info">
                {audit.createdAt && `Executed on: ${format(new Date(audit.createdAt), 'MM/dd/yyyy hh:mm a')}`}
              </Box>
            </Box>
          }
          titleProps={{ width: '100%' }}
          data={auditRows}
        />
      );
    });
  }, [workflowAuditsData, getWorkflowAuditData]);

  return (
    <>
      <InfoContainer
        color="neutral.main"
        className="evaluation-info-container"
        title={
          <Box className="evaluation-info-container__header">
            <Box>Decision Summary</Box>
            <Box className="evaluation-info-container__header__update-info">
              {workflowAuditForLatestReport?.createdAt &&
                `Executed on: ${format(new Date(workflowAuditForLatestReport.createdAt), 'MM/dd/yyyy hh:mm a')}`}
            </Box>
          </Box>
        }
        titleProps={{ width: '100%' }}
        data={getWorkflowAuditData(workflowAuditForLatestReport, true)}
      />
      <Modal isOpen={isEvaluationModalOpen} title="All Evaluations" onClose={closeEvaluationsModal}>
        {allEvaluationsContent}
      </Modal>
    </>
  );
};
