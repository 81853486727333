import React from 'react';
import { Box } from '@theguarantors/ui-kit-v3/design-system';
import { QuestionnaireInfo } from '../components/QuestionnaireInfo/questionnaire-info.component';
import { CurrentPolicyInfo } from '../components/CurrentPolicyInfo/current-policy-info.component';
import { PaymentInfo } from '../components/PaymentInfo/payment-info.component';
import { CoverageInputInfo } from '../components/CoverageInputInfo/coverage-input-info.component';
import { QuoteInfo } from '../components/QuoteInfo/quote-info.component';
import { DefaultCoverageTiersInfo } from '../components/DefaultCoverageTiersInfo/default-coverage-tiers.component';

import { OperationVariables, ApolloQueryResult } from '@apollo/client';

import {
  PolicyResponse,
  QuoteData,
  ApplicationDetailsResponseApplication,
  DetailsChanges,
  CoverageInputDifferences,
  ApplicationDetailsResponse,
} from '../details.types';
import { DecisionSummaryInfo } from '../components/DecisionSummaryInfo/decision-summary-info.component';
import { removeUndefinedFields } from './removeUndefinedFields';

interface ContentColumnsParams {
  application: ApplicationDetailsResponseApplication | undefined;
  changes: Partial<DetailsChanges>;
  handleChanges: (name: keyof DetailsChanges, changes: Record<string, unknown> | undefined) => void;
  coverageInputDifferences: CoverageInputDifferences | null;
  refetch: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<ApplicationDetailsResponse>>;
  currentPolicy: PolicyResponse | null;
  paymentData: any;
  showPaymentCard: boolean;
  addDefaultCoverageRules: string;
  generateQuoteData?: QuoteData | null;
  showDecisionSummaryInfo?: boolean;
}

interface ContentColumnsResponse {
  leftColumn: Record<string, any>;
  rightColumn: Record<string, any>;
}

export function contentColumns({
  application,
  changes,
  handleChanges,
  coverageInputDifferences,
  refetch,
  currentPolicy,
  paymentData,
  showPaymentCard,
  addDefaultCoverageRules,
  generateQuoteData,
  showDecisionSummaryInfo = false,
}: ContentColumnsParams): ContentColumnsResponse {
  const answers = application?.reply?.answers ?? [];
  const riskCategory = application?.normalizedFields?.riskCategory ?? '';
  const isMonthlyPayment = application?.settings?.isMonthlyPayment ?? false;
  const quoteData: QuoteData | null | undefined = generateQuoteData;
  const latestReportId = Math.max(...(application?.reports?.map((report) => report.id) ?? []));
  const latestReport = application?.reports?.find((report) => report.id === latestReportId);
  const workflowAuditForLatestReport = application?.workflowAudits?.find(
    (audit) => audit?.record?.id === latestReport?.id,
  );
  const equifaxResponse =
    workflowAuditForLatestReport?.payload && JSON.parse(workflowAuditForLatestReport.payload)?.responses?.equifax;

  const coverageInputInfo = (
    <CoverageInputInfo
      application={application}
      changes={changes}
      setChanges={handleChanges}
      coverageInputDifferences={coverageInputDifferences}
      refetch={refetch}
    />
  );

  const currentPolicyInfo = currentPolicy && application && (
    <CurrentPolicyInfo
      dealId={application.deal.id}
      policyData={currentPolicy}
      quoteData={quoteData}
      policyHistory={application.policies}
      riskCategory={riskCategory}
      coverageInputDifferences={coverageInputDifferences}
      applicationId={application.id}
      refetch={refetch}
      isVouchedPremium={paymentData.isVouchedPremium}
      isMonthlyPayment={paymentData.isMonthlyPayment}
      amountPaid={paymentData.amountPaid}
    />
  );

  const quoteInfo = application && (
    <Box>
      <QuoteInfo application={application} isMonthlyPayment={isMonthlyPayment} />
    </Box>
  );

  const paymentInfo = showPaymentCard && (
    <Box mb="lg" className="fs-exclude">
      <PaymentInfo data={paymentData} isMonthlyPayment={isMonthlyPayment} refetch={refetch} />
    </Box>
  );

  const questionnaireInfo: 0 | null | undefined | JSX.Element = (answers.length || application?.normalizedFields) && (
    <Box mb="lg">
      <QuestionnaireInfo
        answers={answers}
        reportData={application?.normalizedFields?.recordCheck}
        equifaxResponse={equifaxResponse}
        showDecisionSummaryInfoEnabled={showDecisionSummaryInfo}
      />
    </Box>
  );

  const defaultCoverageTiersInfo: React.ReactElement | null = (
    <DefaultCoverageTiersInfo data={application?.lease?.property?.defaultCoverageRules} />
  );

  const decisionSummaryInfo: React.ReactElement | null = (
    <Box>
      <DecisionSummaryInfo application={application} />
    </Box>
  );

  let leftColumn;
  let rightColumn;
  if (addDefaultCoverageRules === 'on') {
    leftColumn = {
      coverageInputInfo,
      defaultCoverageTiersInfo,
      questionnaireInfo,
    };
    rightColumn = {
      decisionSummaryInfo: showDecisionSummaryInfo ? decisionSummaryInfo : undefined,
      paymentInfo,
      quoteInfo,
      currentPolicyInfo,
    };
  } else {
    leftColumn = {
      coverageInputInfo,
      currentPolicyInfo,
      quoteInfo,
    };
    rightColumn = {
      decisionSummaryInfo: showDecisionSummaryInfo ? decisionSummaryInfo : undefined,
      paymentInfo,
      questionnaireInfo,
    };
  }

  removeUndefinedFields(leftColumn);
  removeUndefinedFields(rightColumn);

  return { leftColumn, rightColumn };
}
