import React from 'react';

export const AccessTimeFilledIcon: React.FC = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333336 6.99998C0.333336 3.33331 3.33334 0.333313 7 0.333313C10.6667 0.333313 13.6667 3.33331 13.6667 6.99998C13.6667 10.6666 10.6667 13.6666 7 13.6666C3.33334 13.6666 0.333336 10.6666 0.333336 6.99998ZM6.33334 7.66665L9.8 9.79998L10.3333 8.93331L7.33334 7.13331V3.66665H6.33334V7.66665Z"
        fill="#161657"
      />
    </svg>
  );
};
