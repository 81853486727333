import { Box } from '@theguarantors/ui-kit-v3';
import React from 'react';
import {
  reportStatusDisplayNameMap,
  reportStatusToBackgroundColorMap,
  reportStatusToColorMap,
  reportStatusToIconMap,
} from '../decision-summary-info-helpers';
import { REPORT_STATUS } from '../../../../../api/api-types';

export const DecisionFinalResultStatus: React.FC<{
  readonly status: REPORT_STATUS;
}> = ({ status }) => {
  const statusLowerCase = status?.toLowerCase();

  const color = statusLowerCase ? reportStatusToColorMap[statusLowerCase] : '#161657';
  const backgroundColor = statusLowerCase ? reportStatusToBackgroundColorMap[statusLowerCase] : '#161657';
  const StatusIcon = statusLowerCase && reportStatusToIconMap[statusLowerCase];
  const reportStatus = reportStatusDisplayNameMap[status] || status;

  return (
    <Box className="step-audit">
      <Box className="step-audit__status" style={{ color, backgroundColor, padding: '8px 12px' }}>
        {StatusIcon && <StatusIcon className="decision-summary-icon" />}
        <Box ml="4px" style={{ textTransform: 'capitalize' }}>
          {reportStatus}
        </Box>
      </Box>
    </Box>
  );
};
