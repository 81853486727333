import { ApolloError, ApolloQueryResult, gql, OperationVariables, useQuery } from '@apollo/client';
import { ApplicationDetailsResponseApplication, ApplicationDetailsResponse } from '../details.types';

interface Result {
  application: ApplicationDetailsResponseApplication | undefined;
  loading: boolean;
  refetch: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<ApplicationDetailsResponse>>;
  error?: ApolloError;
}

export const ApplicationDetails = gql`
  query ApplicationDetails($applicationId: Int!) {
    application(applicationId: $applicationId) {
      id
      firstName
      lastName
      status
      applicantEmail
      externalApplicationId
      phoneNumber
      lgCoverageOverride
      sdrCoverageOverride
      screeningStatus
      normalizedFields {
        riskCategory
        recordCheck {
          ficoScore
          isFirstNameMatched
          isLastNameMatched
          isSsnMatched
          isDateOfBirthMatched
          isAddressMatched
        }
        autoUWVersion
      }
      policies {
        id
        bondNumber
        status
        createdAt
        deal {
          id
          carrier
        }
        lease {
          addressUnit
          leaseStartDate
          leaseEndDate
          property {
            id
            displayName
            mainAddress
            city
            state
            zipCode
            llc
          }
          organization {
            name
          }
          rentCoverage
          depositsCoverage
          freeRent
          prepaidRent
        }
        premium {
          amount
          context {
            monthlyRent
            coverageMonths
            amountSDR
            premiumRate
            penalSum
            premiumAmountWithoutUpfrontDiscount
            premiumAmountBeforeTax
          }
        }
        documents {
          id
          fileId
          type
        }
        previousPolicyId
      }

      lease {
        id
        propertyId
        leaseStartDate
        leaseEndDate
        monthlyRent
        addressUnit
        rentCoverage
        depositsCoverage
        freeRent
        prepaidRent

        property {
          id
          displayName
          mainAddress
          state
          city
          zipCode
          primaryRole {
            contact {
              firstName
              lastName
              email
              phoneNumber
            }
          }
        }
      }

      contact {
        firstName
        lastName
        email
        phoneNumber
      }

      deal {
        id
        carrier
      }

      invitation {
        type
      }

      reply {
        answers {
          fieldId
          value
        }
      }

      payment {
        amountPaid
        amountRefunded
        outstandingAmount
        history {
          id
          status
          paymentMethod
          amount
          date
        }
      }

      quote {
        ... on ApplicationQuote {
          # for payment section
          premiumAmount
          premiumAmountWithoutUpfrontDiscount
          lgPremiumAmountWithoutUpfrontDiscount
          sdrPremiumAmountWithoutUpfrontDiscount

          penalSum
          amountSDR
          coverageMonths
          premiumRate
          rateFilingData
          riskCategory
          carrier
          premium
          addRiskPremium
          lgPremiumAmount
          sdrPremiumAmount
          stampingFee
          surplusLinesTax
          monthlyPaymentTaxes {
            stampingFee
            surplusLinesTax
            premiumAmountBeforeTax
          }
        }
        ... on Quote {
          rateFilingData
          premiumRate
          monthlyRent
          rentCoverageMonths
          depositCoverageAmount
          output {
            monthlyPayment {
              rentCoveragePenalSum
              depositCoveragePenalSum
              policyPrice
              rentCoveragePremiumFinal
              depositCoveragePremiumFinal
              finalPremium
            }
            upfrontPayment {
              rentCoveragePenalSum
              depositCoveragePenalSum
              policyPrice
              rentCoveragePremiumFinal
              depositCoveragePremiumFinal
              finalPremium
            }
          }
        }
      }

      renterProvidedData {
        leaseMonths
        monthlyRent
        apartmentNumber
      }

      settings {
        comment
        isMonthlyPayment
        isVouchedPremium
        firstMonthlyPaymentDate
      }

      reports {
        id
        service
        status
        serviceData {
          response {
            ficoScore
          }
        }
        createdAt
        updatedAt
        contactId
        referenceType
        referenceId
      }

      workflowAudits {
        id
        evaluationType
        name
        payload
        stepAudit {
          id
          step
          name
          description
          status
        }
        record {
          id
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const ApplicationDetailsWithPropertyCoverages = gql`
  query ApplicationDetails($applicationId: Int!) {
    application(applicationId: $applicationId) {
      id
      firstName
      lastName
      status
      applicantEmail
      externalApplicationId
      phoneNumber
      lgCoverageOverride
      sdrCoverageOverride
      screeningStatus
      normalizedFields {
        riskCategory
        recordCheck {
          ficoScore
          isFirstNameMatched
          isLastNameMatched
          isSsnMatched
          isDateOfBirthMatched
          isAddressMatched
        }
        autoUWVersion
      }
      policies {
        id
        bondNumber
        status
        createdAt
        deal {
          id
          carrier
        }
        lease {
          addressUnit
          leaseStartDate
          leaseEndDate
          property {
            id
            displayName
            mainAddress
            city
            state
            zipCode
            llc
          }
          organization {
            name
          }
          rentCoverage
          depositsCoverage
          freeRent
          prepaidRent
        }
        premium {
          amount
          context {
            monthlyRent
            coverageMonths
            amountSDR
            premiumRate
            penalSum
            premiumAmountWithoutUpfrontDiscount
            premiumAmountBeforeTax
          }
        }
        documents {
          id
          fileId
          type
        }
        previousPolicyId
      }

      lease {
        id
        propertyId
        leaseStartDate
        leaseEndDate
        monthlyRent
        addressUnit
        rentCoverage
        depositsCoverage
        freeRent
        prepaidRent

        property {
          id
          displayName
          mainAddress
          state
          city
          zipCode
          primaryRole {
            contact {
              firstName
              lastName
              email
              phoneNumber
            }
          }
          defaultCoverageRules {
            denied {
              lgCoverage
              sdrCoverage
              sdrCoverageType
            }
            conditional {
              lgCoverage
              sdrCoverage
              sdrCoverageType
            }
            approved {
              lgCoverage
              sdrCoverage
              sdrCoverageType
            }
          }
        }
      }

      contact {
        firstName
        lastName
        email
        phoneNumber
      }

      deal {
        id
      }

      invitation {
        type
      }

      reply {
        answers {
          fieldId
          value
        }
      }

      payment {
        amountPaid
        amountRefunded
        outstandingAmount
        history {
          id
          status
          paymentMethod
          amount
          date
        }
      }

      quote {
        ... on ApplicationQuote {
          # for payment section
          premiumAmount
          premiumAmountWithoutUpfrontDiscount
          lgPremiumAmountWithoutUpfrontDiscount
          sdrPremiumAmountWithoutUpfrontDiscount

          penalSum
          amountSDR
          coverageMonths
          premiumRate
          rateFilingData
          riskCategory
          carrier
          premium
          addRiskPremium
          lgPremiumAmount
          sdrPremiumAmount
          stampingFee
          surplusLinesTax
          monthlyPaymentTaxes {
            stampingFee
            surplusLinesTax
            premiumAmountBeforeTax
          }
        }
        ... on Quote {
          rateFilingData
          premiumRate
          monthlyRent
          rentCoverageMonths
          depositCoverageAmount
          output {
            monthlyPayment {
              rentCoveragePenalSum
              depositCoveragePenalSum
              policyPrice
              rentCoveragePremiumFinal
              depositCoveragePremiumFinal
              finalPremium
            }
            upfrontPayment {
              rentCoveragePenalSum
              depositCoveragePenalSum
              policyPrice
              rentCoveragePremiumFinal
              depositCoveragePremiumFinal
              finalPremium
            }
          }
        }
      }

      renterProvidedData {
        leaseMonths
        monthlyRent
        apartmentNumber
      }

      settings {
        comment
        isMonthlyPayment
        isVouchedPremium
        firstMonthlyPaymentDate
      }

      reports {
        id
        service
        status
        serviceData {
          response {
            ficoScore
          }
        }
        createdAt
        updatedAt
        contactId
        referenceType
        referenceId
      }

      workflowAudits {
        id
        evaluationType
        name
        payload
        stepAudit {
          id
          step
          name
          description
          status
        }
        record {
          id
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export function useApplicationDetails(applicationId: number, addDefaultCoverageRules = false): Result {
  const { data, loading, error, refetch } = useQuery<ApplicationDetailsResponse>(
    addDefaultCoverageRules ? ApplicationDetailsWithPropertyCoverages : ApplicationDetails,
    {
      variables: { applicationId },
    },
  );

  return { application: data?.application, loading, error, refetch };
}
