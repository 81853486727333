import React from 'react';
import { Cancel, CheckCircle, ErrorRounded, Warning } from '@material-ui/icons';
import { STEP_AUDIT_STATUS, STEP_AUDIT_STATUS_RAW } from './decision-summary-info.types';
import { REPORT_STATUS } from '../../../../api/api-types';
import { AccessTimeFilledIcon } from './components/access-time-filled-icon';

export const executedStatuses = [STEP_AUDIT_STATUS.PASS, STEP_AUDIT_STATUS.FAIL, STEP_AUDIT_STATUS.ERROR];
export const recordStatusesToShow = [
  REPORT_STATUS.APPROVED,
  REPORT_STATUS.PENDING,
  REPORT_STATUS.DENIED,
  REPORT_STATUS.EXCEPTION,
];

export const statusMap: Record<string, STEP_AUDIT_STATUS> = {
  [STEP_AUDIT_STATUS_RAW.PASSED]: STEP_AUDIT_STATUS.PASS,
  [STEP_AUDIT_STATUS_RAW.FAILED]: STEP_AUDIT_STATUS.FAIL,
  [STEP_AUDIT_STATUS_RAW.ERROR]: STEP_AUDIT_STATUS.ERROR,
  [STEP_AUDIT_STATUS_RAW.NOT_EXECUTED]: STEP_AUDIT_STATUS.NOT_EXECUTED,
};

export const getStepAuditStatus = (status?: string): STEP_AUDIT_STATUS =>
  statusMap[status ?? ''] ?? STEP_AUDIT_STATUS.NOT_EXECUTED;

export const auditStatusToColorMap: Record<STEP_AUDIT_STATUS, string> = {
  [STEP_AUDIT_STATUS.PASS]: '#1FDDB3',
  [STEP_AUDIT_STATUS.FAIL]: '#AF332C',
  [STEP_AUDIT_STATUS.ERROR]: '#F3BE55',
  [STEP_AUDIT_STATUS.NOT_EXECUTED]: '#161657',
};

export const auditStatusToBackgroundColorMap: Record<STEP_AUDIT_STATUS, string> = {
  [STEP_AUDIT_STATUS.PASS]: '#E9F8F8',
  [STEP_AUDIT_STATUS.FAIL]: '#FAEEEE',
  [STEP_AUDIT_STATUS.ERROR]: '#FFFAF0',
  [STEP_AUDIT_STATUS.NOT_EXECUTED]: '',
};

export const auditStatusToIconMap: Record<STEP_AUDIT_STATUS, React.FC<any> | null> = {
  [STEP_AUDIT_STATUS.PASS]: CheckCircle,
  [STEP_AUDIT_STATUS.FAIL]: Cancel,
  [STEP_AUDIT_STATUS.ERROR]: Warning,
  [STEP_AUDIT_STATUS.NOT_EXECUTED]: null,
};

export const reportStatusToColorMap: Record<string, string> = {
  approved: '#161657',
  pending: '#161657',
  denied: '#FFF',
  exception: '#FFF',
};

export const reportStatusToBackgroundColorMap: Record<string, string> = {
  approved: '#1FDDB3',
  pending: '#F3BE55',
  denied: '#AF332C',
  exception: '#161657',
};

export const reportStatusToIconMap: Record<string, React.FC<any>> = {
  approved: CheckCircle,
  pending: AccessTimeFilledIcon,
  denied: Cancel,
  exception: ErrorRounded,
};

export const serviceNameToModalTitleMap: Record<string, string> = {
  sentilink: 'Sentilink Report',
  equifax: 'Equifax Report',
  riskModel: 'Risk Model Report',
};

export const reportStatusDisplayNameMap: Record<string, string> = {
  [REPORT_STATUS.APPROVED]: 'Approved',
  [REPORT_STATUS.PENDING]: 'Pending Lease Details',
  [REPORT_STATUS.DENIED]: 'Denied',
  [REPORT_STATUS.EXCEPTION]: 'Exception',
};
