import { FeatureFlag } from './feature-flag';
import useFeatureFlag from './use-feature-flag';

import { LeaseEntity } from '../../api/api-types';
import { datadogLogs } from '@datadog/browser-logs';

export const useIsBuildingPricingRefactoringEnabled = (lease: LeaseEntity | undefined): boolean => {
  const buildingId = lease?.propertyId ?? lease?.property?.id;
  const [featureFlag, { isReady, isControl, isEnabled }] = useFeatureFlag(
    FeatureFlag.PRICING_REFACTORING,
    String(buildingId),
  );
  datadogLogs.logger.info('Building eligibility check for pricing refactoring', {
    isBuildingEnabled: isEnabled,
    isReady,
    isControl,
    featureFlag,
    buildingId,
    method: 'useIsBuildingPricingRefactoringEnabled',
  });
  return isEnabled;
};
