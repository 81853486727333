import { Box } from '@theguarantors/ui-kit-v3';
import React from 'react';

export const ReportIcon: React.FC<{ readonly onClick: () => void }> = ({ onClick }) => {
  return (
    <Box className="report-icon__wrapper" onClick={onClick}>
      <svg width="16" height="21" viewBox="0 0 16 21" xmlns="http://www.w3.org/2000/svg">
        <path
          className="report-icon"
          d="M4 16.675H12V14.675H4V16.675ZM4 12.675H12V10.675H4V12.675ZM2 20.675C1.45 20.675 0.979167 20.4792 0.5875 20.0875C0.195833 19.6958 0 19.225 0 18.675V2.67499C0 2.12499 0.195833 1.65415 0.5875 1.26249C0.979167 0.870821 1.45 0.674988 2 0.674988H10L16 6.67499V18.675C16 19.225 15.8042 19.6958 15.4125 20.0875C15.0208 20.4792 14.55 20.675 14 20.675H2ZM9 7.67499V2.67499H2V18.675H14V7.67499H9Z"
          fill="#3F3CEC"
        />
      </svg>
    </Box>
  );
};
