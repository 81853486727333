import gql from 'graphql-tag';

export const RecordTypeDef = gql`
  extend type Query {
    report(id: Int!): ReportEntity
    applicationReports(applicationId: Int!): [ReportEntity]
    workflowAudit(reportId: Int!): WorkflowAuditEntity
    workflow: WorkflowEntity
  }

  type ReportEntity {
    id: Int!
    service: String!
    status: String!
    serviceData: ReportServiceData
    contactData: ContactEntity!
    createdAt: String
    updatedAt: String
    contactId: Int!
    referenceType: String!
    referenceId: Int!
    provider: ReportProvider
  }

  type ReportServiceData {
    response: ReportServiceDataResponse
  }

  type ReportServiceDataResponse {
    ficoScore: Int
    isSsnMatched: Boolean
    ficoReasonsCount: Int
    isLastNameMatched: Boolean
    unpaidCollections: Boolean
    isFirstNameMatched: Boolean
    bankruptcyDateFiled: Boolean
    isDateOfBirthMatched: Boolean
    bankruptcyCurrentDispositionDate: String
  }

  type ReportProvider {
    id: Int!
    name: String!
    alias: String!
    dataSchema: ReportProviderDataSchema!
    createdAt: String
    updatedAt: String
  }

  type ReportProviderDataSchema {
    ficoscore: String!
  }

  type WorkflowAuditEntity {
    id: Int!
    name: String
    status: String
    payload: String
    evaluationType: String
    stepAudit: [StepAudit]
    record: ReportEntity
    createdAt: String
    updatedAt: String
  }

  type WorkflowAuditPayload {
    responses: [String]
  }

  type StepAudit {
    step: Int
    name: String
    id: String
    type: String
    status: String
    description: String
    message: String
    status_code: String
  }

  type WorkflowEntity {
    id: Int!
    name: String
    providerName: String
    schema: [WorkflowStep]
    isActive: Boolean
    createdAt: String
    updatedAt: String
  }

  type WorkflowStep {
    order: Int!
    name: String
    displayName: String
    id: String
    type: String
    description: String
    jumpToStepRulesMatch: Int
    jumpToStepRulesNoMatch: Int
    payloadFieldName: String
    payloadFieldValue: String
  }

  type WorkflowStepRulesSingleArray {
    rules: [StepRule]
  }

  type WorkflowStepRulesDoubleArray {
    rules: [[StepRule]]
  }

  union WorkflowStepRules = WorkflowStepRulesDoubleArray | WorkflowStepRulesSingleArray

  extend type WorkflowStep {
    rules: WorkflowStepRules
  }

  type StepRule {
    field: String
    operator: String
  }

  type StepRuleValueString {
    value: String
  }

  type StepRuleValueFloat {
    value: Float
  }

  type StepRuleValueBoolean {
    value: Boolean
  }

  type StepRuleValueStringArray {
    value: [String]
  }

  type StepRuleValueFloatArray {
    value: [Float]
  }

  union StepRuleValue =
      StepRuleValueString
    | StepRuleValueFloat
    | StepRuleValueBoolean
    | StepRuleValueStringArray
    | StepRuleValueFloatArray

  extend type StepRule {
    value: StepRuleValue
  }
`;

export default [RecordTypeDef];
