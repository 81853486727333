import { SplitContext, useSplitTreatments } from '@splitsoftware/splitio-react';
import { useContext } from 'react';
import { FeatureFlag } from './feature-flag';

export default function useFeatureFlag(
  flagName: FeatureFlag,
  splitKey?: string,
): [string, { isReady: boolean; isControl: boolean; isEnabled: boolean }] {
  const { isReady } = useContext(SplitContext);

  const { treatments } = useSplitTreatments({
    names: [flagName],
    splitKey,
  });
  const featureFlag = treatments[flagName].treatment;
  const isEnabled = featureFlag === 'on';

  return [featureFlag, { isReady, isControl: featureFlag === 'control', isEnabled }];
}
