import React, { useCallback, useState } from 'react';
import {
  auditStatusToBackgroundColorMap,
  auditStatusToColorMap,
  auditStatusToIconMap,
  executedStatuses,
  serviceNameToModalTitleMap,
} from '../decision-summary-info-helpers';
import { STEP_AUDIT_STATUS } from '../decision-summary-info.types';
import { Box } from '@theguarantors/ui-kit-v3';
import { Modal } from './modal.component';
import { ReportIcon } from './report-icon';
import JsonFormatter from 'react-json-formatter';

export const StepAuditStatus: React.FC<{
  readonly status: STEP_AUDIT_STATUS;
  readonly modalData?: { serviceName: string; rawResponse: Record<string, unknown> };
}> = ({ status, modalData }) => {
  const [isRawResponseModalOpen, setIsRawResponseModalOpen] = useState<boolean>(false);

  const openEvaluationsModal = useCallback(() => {
    setIsRawResponseModalOpen(true);
  }, [setIsRawResponseModalOpen]);

  const closeEvaluationsModal = useCallback(() => {
    setIsRawResponseModalOpen(false);
  }, [setIsRawResponseModalOpen]);

  const backgroundColor = auditStatusToBackgroundColorMap[status];
  const color = auditStatusToColorMap[status];

  const border = executedStatuses.includes(status) ? `1px solid ${color}` : '';
  const padding = executedStatuses.includes(status) ? '8px 12px' : '8px 0';
  const StatusIcon = auditStatusToIconMap[status];

  return (
    <Box className="step-audit">
      <Box className="step-audit__status" style={{ border, backgroundColor, padding }}>
        {executedStatuses.includes(status) && StatusIcon && (
          <StatusIcon className="decision-summary-icon" style={{ color }} />
        )}
        <Box ml="4px">{status}</Box>
      </Box>
      {modalData ? (
        <>
          <ReportIcon onClick={openEvaluationsModal} />
          <Modal
            isOpen={isRawResponseModalOpen}
            title={serviceNameToModalTitleMap[modalData.serviceName]}
            onClose={closeEvaluationsModal}
          >
            <Box className="step-audit-report__json-view">
              <JsonFormatter
                json={JSON.stringify(modalData.rawResponse)}
                tabWith={4}
                jsonStyle={{
                  style: { color: '#161657' },
                  propertyStyle: { color: '#3F3CEC' },
                }}
              />
            </Box>
          </Modal>
        </>
      ) : (
        <Box className="step-audit__additional-info">—</Box>
      )}
    </Box>
  );
};
