import { Tooltip } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { Box } from '@theguarantors/ui-kit-v3';
import React from 'react';

export const StepAuditInfo: React.FC<{ readonly name?: string; readonly description?: string }> = ({
  name,
  description,
}) => {
  return (
    <Box className="step-name__wrapper">
      <Box className="step-name">{name}</Box>
      <Box className="step-name__tooltip-wrapper">
        {description ? (
          <Tooltip title={description} placement="top-start">
            <Box>
              <ErrorOutline className="decision-summary-icon" style={{ color: '#73739A' }} />
            </Box>
          </Tooltip>
        ) : null}
      </Box>
    </Box>
  );
};
