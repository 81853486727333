import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { WorkflowEntity } from '../components/DecisionSummaryInfo/decision-summary-info.types';

export interface WorkflowData {
  workflow: WorkflowEntity;
}

export const Workflow = gql`
  query Workflow {
    workflow {
      id
      name
      providerName
      schema {
        order
        name
        displayName
        id
        type
        description
        jumpToStepRulesMatch
        jumpToStepRulesNoMatch
        payloadFieldName
        payloadFieldValue
      }
      isActive
      createdAt
      updatedAt
    }
  }
`;

export const useWorkflow = () => {
  const { data, loading, error, refetch } = useQuery<WorkflowData>(Workflow);

  return {
    refetch,
    loading,
    error,
    data: data?.workflow,
  };
};
